<template>

    <div class="builder-view uk-margin-large-bottom">

        <!-- This is the modal -->
        <div id="modal-example" ref="loginModal" class="uk-modal-container uk-modal-container-small" uk-modal>
            <div class="uk-modal-dialog  uk-modal-body uk-margin-auto-vertical ">
                <button class="uk-modal-close-default uk-close-large" type="button" uk-close></button>
                <h2 class="uk-modal-title uk-text-center" v-if="loginPromptMode == 0">Getting a New Device?</h2>
                <div class="modal-content uk-flex uk-margin-top uk-text-center" v-if="loginPromptMode == 0">
                    <div class="uk-panel  uk-width-1-3 uk-padding-small">
                        <h3 class=" uk-margin-remove-bottom">New customer?</h3>
                        <p class="uk-margin-remove-top">&nbsp;</p>
                        <div class="action-block uk-margin-top uk-flex uk-flex-between">
                            <div class="action-sub-block uk-text-center uk-width-expand">
                                <div class="uk-flex uk-flex-center uk-text-center uk-margin-small-bottom" >
                                    <span uk-icon="icon:user;ratio:3"></span>
                                </div>
                                <form-button type="primary" @click="closeLoginPrompt()">Continue my visit</form-button>
                            </div>
                        </div>
                    </div>
                    <div class="uk-panel uk-background-muted  uk-width-2-3 uk-padding-small">
                        <h3 class=" uk-margin-remove-bottom">Already a customer?</h3>
                        <p class="uk-margin-remove-top">Take advantage of personalized prices & offers!</p>
                        <div class="action-block uk-margin-top uk-flex uk-flex-between" >
                            <div class="action-sub-block uk-text-center uk-width-expand">
                                <div class="uk-flex uk-flex-center uk-text-center uk-margin-small-bottom" >
                                    <span uk-icon="icon:refresh;ratio:3"></span>
                                </div>
                                <form-button type="primary" @click="loginPromptMode = 1">Change phone</form-button>
                            </div>
                            <div class="action-sub-block uk-text-center uk-width-expand">
                                <div class="uk-flex uk-flex-center uk-text-center uk-margin-small-bottom" >
                                    <span uk-icon="icon:plus;ratio:3"></span>
                                </div>
                                <form-button type="primary" @click="loginPromptMode = 1">Open a new line</form-button>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 class="uk-modal-title uk-text-center uk-animation-slide-top-small" v-if="loginPromptMode == 1">Please Login</h2>
                <div class="modal-content uk-margin-top  uk-animation-slide-bottom-small" v-if="loginPromptMode == 1">
                    <div class="uk-container-xsmall uk-margin-auto uk-margin-large-bottom-  uk-flex uk-flex-center">
                        <base-form
                                action="/session"
                                method="post"
                                class="uk-margin-remove uk-width-expand"
                                :field-wrapper-margin="false"
                                formPath="form/user-login"
                                :expectedFields="3"
                                @form:submitSuccess="demoLogin()"
                        ></base-form>
                    </div>

                </div>

            </div>
        </div>

        <promotion-banner  v-if=" ! finished"></promotion-banner>



        <!-- breadcrumbs -->
        <!--
        <div class="uk-container-xlarge uk-margin-auto uk-margin  uk-padding-small">
            <ul class="uk-breadcrumb">
                <li><a href="#shop">Shop</a></li>
                <li><a @click="$router.push({name: 'ecommerce-category', params: {'categoryAlias': 'devices'}})">Devices</a></li>
                <li class="uk-disabled"><a>Apple iPhone 12 Pro</a></li>
            </ul>
        </div>-->

        <!--builder body -->

        <div class="wrap uk-margin-auto uk-flex uk-flex-stretch uk-flex-between uk-container-xlarge"  v-if=" ! finished">
            <div v-if=" ! finished"
                 class="builder-main uk-width-expand uk-margin-top uk-margin-auto uk-padding uk-padding-remove-vertical ">

                <!-- progress -->
                <div

                        class="builder-progress  uk-padding-remove-vertical uk-container-xlarge uk-margin-auto uk-margin-bottom "
                        style="left: 0;right: 0;width: 100%;"
                        > <!--uk-sticky="offset:120; top:20;"-->
                    <div class="summary-steps uk-padding-small  uk-background-default  uk-margin-small-bottom uk-box-shadow-medium">
                        <ul class="uk-tab uk-subnav uk-subnav-pill uk-steps uk-flex  uk-margin-remove-bottom " uk-tab>
                            <li class="uk-step-complete uk-width-expand"
                                :class="{'uk-step-complete' : steps[0].complete, 'uk-active': currentStep == 0}"
                                @click="currentStep = 0">
                                <a href="">
                                    <span uk-icon="check"></span>
                                    <span>Your Subscription</span>
                                </a>
                            </li>
                            <li class="uk-events-none">
                                <span class="uk-text-secondary" uk-icon="arrow-right"></span>
                            </li>
                            <li class="uk-step-complete uk-width-expand"
                                :class="{'uk-step-complete' : steps[1].complete, 'uk-active': currentStep == 1}"
                                @click="currentStep = 1">
                                <a href="">
                                    <span uk-icon="check"></span>
                                    <span>Your Device</span>
                                </a>
                            </li>
                            <li class="uk-events-none">
                                <span class="uk-text-secondary" uk-icon="arrow-right"></span>
                            </li>
                            <li class="uk-step uk-width-expand"
                                :class="{'uk-step-complete' : steps[2].complete, 'uk-active': currentStep == 2}"
                                @click="currentStep = 2">
                                <a href="">
                                    <span uk-icon="check" v-if="steps[2].complete"></span>
                                    <span uk-icon="hashtag" v-else></span>
                                    <span>Your New Number</span>
                                </a>
                            </li>


                        </ul>
                    </div>
                </div>
                <!-- subscription-->
                <div class="builder-step-content uk-width-3-3 uk-animation-slide-left-small" v-show="currentStep == 0 ">
                    <div class="builder-step builder-step--subscription">
                        <!--
                        <div class="uk-margin-large-bottom  ">
                            <div class="product-title">
                                <h1 class="uk-display-inline-block uk-margin-remove-bottom">{{currentPackage.title}}</h1><br>
                                <span v-html="currentPackage.subTitle"></span>
                            </div>
                        </div> -->
                        <div class="selected-subscription-summary uk-flex">
                            <!--
                            <div class="media uk-width-1-3 uk-margin-right uk-visible@xl">
                                <img src="images/nice.jpg" />
                            </div>-->
                            <div class="info  uk-width-expand uk-card uk-card-default uk-card-body uk-box-shadow-medium uk-padding-remove uk-overflow-auto">
                                <div class="uk-card-hat uk-margin-remove-bottom uk-background-secondary uk-text-inverse-color">
                                    <span class=" uk-text-inverse-color uk-margin-remove">{{currentPackage.dataMethod}}</span>
                                </div>

                                <div class="inner uk-flex">
                                    <div class="uk-card-media-left uk-cover-container" style="max-width: 200px;">
                                        <img src="images/nice.jpg"  alt="" uk-cover>
                                        <canvas width="200"  style="min-height: 400px;height: 100%;"></canvas>
                                    </div>
                                    <div class="package-content uk-margin-top uk-padding-small uk-padding-remove-vertical uk-margin-bottom uk-width-expand">

                                        <div class="package-options uk-text-center">
                                            <h3 class=" uk-margin-remove-bottom">
                                                <strong v-if="demoMode != 'reengage'">Customise your package</strong>
                                                <strong v-if="demoMode == 'reengage'">Reengage with us, Aldwin!</strong>
                                            </h3>
                                            <p class=" package-options-explain uk-margin-remove-top">
                                                <span v-if="demoMode != 'reengage'">The longer you commit, the more you save!</span>
                                                <span v-if="demoMode == 'reengage'">You are getting our absolutely best prices!</span>
                                            </p>
                                            <div class="subscription-customize uk-flex uk-flex-column uk-flex-middle">
                                                <form-input type="buttonGroup"
                                                            :autoTranslate="false"
                                                            :label="false"
                                                            :wrapperMargins="false"
                                                            v-model="addToCart.builder.package.options.commitment"
                                                            :list="[
                                                    {value: 0, label: 'No commitment'},
                                                    {value: 12, label: '12 Month commitment'},
                                                    {value: 24, label: '24 Month commitment'},
                                                    ]"></form-input>
                                                <span class="uk-text-primary"
                                                      style="margin-bottom: 4px;"
                                                      uk-icon="icon:chevron-down;ratio:3"></span>
                                                <div class="discount-type-wrapper">
                                                    <div v-if="addToCart.builder.package.options.commitment == 0"
                                                         class="overlay uk-animation-fade" uk-tooltip="Commit for a discount!"></div>
                                                    <form-input type="buttonGroup"

                                                                :autoTranslate="false"
                                                                :label="false"
                                                                :wrapperMargins="false"
                                                                v-model="addToCart.builder.package.options.discountType"
                                                                :list="demoDiscounts"></form-input>
                                                </div>

                                            </div>

                                        </div>
                                        <hr>
                                        <div class="package-price uk-margin-bottom">
                                            <div class="step-product-details-price uk-flex uk-flex-between">
                                                <div class="price uk-width-1-2 uk-margin-left">
                                                    <h3 class="uk-margin-remove-bottom ">
                                                        <strong data-v-749a5bba="">155 F/MO</strong>
                                                    </h3>
                                                    <span class="uk-text-small" >x12 months then 220 F/MO</span>
                                                </div>
                                                <form-button icon-end="chevron-right"
                                                             icon-ratio="1.8"
                                                             type="primary"
                                                             size="large"
                                                             class="uk-text-large"
                                                             @click="currentStep=1">CONTINUE</form-button>

                                            </div>
                                        </div>
                                        <hr>
                                        <div class="package-highlights uk-flex">
                                            <div class="package-features uk-width-1-2 uk-margin-right-small">
                                                <h4 class="uk-text-secondary uk-text-semibold uk-margin-small-bottom">Included in this package</h4>
                                                <ul class="uk-list uk-list-primary uk-list-disc uk-margin-remove">
                                                    <li v-for="include in currentPackage.includes">{{include}}</li>
                                                </ul>
                                            </div>
                                            <div class="package-price package-cta  uk-width-1-2 uk-margin-left-small ">
                                                <h4 class="uk-text-secondary uk-text-semibold uk-margin-small-bottom ">Package Advantages</h4>
                                                <ul class="uk-list advantages-list uk-list-primary uk-list-disc uk-margin-remove">
                                                    <li v-for="benefit in currentPackage.benefits">
                                                        <a :href="benefit.link">{{benefit.label}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="builder-continue uk-margin-large-top">
                                            <form-button @click.prevent="scrollToRef('packageList')" uk-scroll icon="refresh" type="secondary">Choose Another Package</form-button>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div class="reassurance">
                            <div class="uk-flex-uk-flex-start uk-flex uk-flex-middle uk-flex-center uk-margin-large-top uk-margin-auto ">
                                <div class="uk-margin-small-right" uk-icon="icon: lock; ratio:4"></div>
                                <h3 class="uk-margin-remove">Secure Payment<br> Pay securely - your information is protected by SSL encryption.</h3>
                            </div>
                        </div>
                        <hr class="uk-margin-large uk-margin-remove-left uk-margin-remove-right">
                        <div ref="packageList" class="other-packages">
                            <div class="uk-text-center uk-margin-bottom">
                                <h2 class="uk-margin-remove uk-font-bold">All Available Packages</h2>
                                <h4 class="uk-margin-remove">Some sub text that can help boost sales. Highlight Something about the packages</h4>
                            </div>

                            <div uk-slider="finite:true; center:true;index:2">

                                <div class="packages-slider-inner uk-position-relative  ">
                                    <div class="uk-slider-container">
                                        <ul class="packages uk-slider-items uk-overflow-visible">
                                            <div class=" package package-box uk-margin-small-right uk-flex uk-width-1-1 uk-width-1-2@m  uk-width-1-3@l"
                                                 v-for="(plan, index) of packages" > <!-- v-for="plan of packages" :key="plan" -->
                                                <div class="info  uk-card uk-card-default uk-card-body uk-box-shadow-medium uk-padding-remove">
                                                    <div class="uk-card-hat uk-background-secondary uk-text-inverse-color uk-text-center">
                                                        <span class=" uk-text-inverse-color uk-margin-remove">{{plan.dataMethod}}</span>
                                                    </div>

                                                    <div class="package-content uk-padding uk-padding-remove-vertical uk-margin-bottom">
                                                        <div class="package-summary uk-margin-bottom">
                                                            <div class="step-product-details-price ">
                                                                <div class="content">
                                                                    <div><span class="package-limit uk-text-bold">{{plan.limitText}}</span></div>
                                                                    <div><span class="">0 - 24 month commitment</span></div>

                                                                </div>
                                                                <div class="price uk-margin-bottom">
                                                                    <h3 class="uk-margin-remove-bottom ">
                                                                        <strong data-v-749a5bba="">155 F / Month</strong>
                                                                    </h3>
                                                                    <span class="uk-text-small" >x12 months then, 220 F/MO</span>
                                                                </div>
                                                                <div class="cta-wrapper uk-flex uk-flex-center">
                                                                    <form-button type="primary"
                                                                                 v-if="index != addToCart.builder.package.selected"
                                                                                 :fullWidth="true"
                                                                                 @click="addToCart.builder.package.selected = index">Choose</form-button>
                                                                    <form-button type="secondary"
                                                                                 v-if="index == addToCart.builder.package.selected"
                                                                                 :fullWidth="true"
                                                                                 icon="check"
                                                                                 @click="currentStep=1">Selected</form-button>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <hr class="package-info-separator">
                                                        <div class="package-highlights">
                                                            <div class="package-features uk-margin-bottom">
                                                                <h4 class="uk-text-secondary uk-text-semibold uk-margin-small-bottom">Included in this package</h4>
                                                                <ul class="uk-list uk-list-primary uk-list-disc uk-margin-remove">
                                                                    <li v-for="include in plan.includes">{{include}}</li>
                                                                </ul>
                                                            </div>
                                                            <div class="package-detail uk-margin-large-bottom  ">
                                                                <h4 class="uk-text-secondary uk-text-semibold uk-margin-small-bottom ">Package Advantages</h4>
                                                                <ul class="uk-list advantages-list uk-list-primary uk-list-disc uk-margin-remove">
                                                                    <li v-for="benefit in plan.benefits">
                                                                        <a :href="benefit.link">{{benefit.label}}</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </ul>
                                    </div>


                                    <a class="uk-position-center-left-out" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                                    <a class="uk-position-center-right-out" href="#" uk-slidenav-next uk-slider-item="next"></a>

                                </div>

                            </div>

                            <div class="packages">

                            </div>

                        </div>
                    </div>
                </div>

                <!-- phone -->
                <div class="builder-step-content uk-width-3-3 uk-animation-slide-left-small" v-show="currentStep == 1 ">
                    <div class="builder-step builder-step--product">
                        <div class="product-title uk-margin-bottom ">
                            <div class="product-title">
                                <h1 class="uk-display-inline-block uk-margin-remove-bottom">
                                    <span>Apple iPnone 12 Pro Max</span>
                                </h1>&nbsp;
                                <span class="uk-display-inline-block uk-margin-left-small">{{addToCart.options.memory}} Go, {{translate('ecommerceCore.productOptionsDemo.fields.color.options.' + addToCart.options.color)}}</span>
                            </div>
                        </div>
                        <div class="uk-flex product content" >
                            <div class="product-col-1 uk-width-1-2 uk-margin-medium-right">
                                <!--   <div class="product-meta">
                                       [reviews]
                                   </div> -->
                                <div class="product-gallery" uk-slideshow="animation: push">

                                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

                                        <ul class="uk-slideshow-items">
                                            <li v-for="(image, index) of product.images" :key="image">
                                                <img :src="image.src" alt="" uk-cover>
                                            </li>
                                        </ul>

                                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

                                        <ul class="uk-flex product-slideshow-nav uk-thumbnav uk-flex-center uk-margin">
                                            <li class="uk-flex" :uk-slideshow-item="index" v-for="(image, index) of product.images" :key="image">
                                                <a href="#" class="uk-position-relative" :style="sourceToBackgroundCss(image.thumbSrc)"></a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                                <!--      <div class="product-highlight uk-text-center">[product highlights]</div> -->
                            </div>
                            <div class="product-primary-content uk-width-1-2">
                                <div class="product-details">
                                    <h3 class="uk-margin-remove-bottom">Personalize your Device.</h3>
                                    <p class="uk-margin-remove-top">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                </div>

                                <div class="product-highlights uk-margin-small-bottom uk-flex uk-flex-wrap uk-flex-middle uk-flex-between">
                                    <div class="product-add uk-margin-remove uk-width-expand">

                                        <div class="product-options uk-flex uk-flex-between uk-flex-top">

                                            <base-form
                                                    action="/session"
                                                    method="post"
                                                    class="uk-margin-remove"
                                                    :field-wrapper-margin="false"
                                                    formPath="form/ecommerce-productBuilderDeviceDemo"
                                                    :expectedFields="3"

                                                    v-model="addToCart.options"
                                            ></base-form>
                                        </div>
                                        <form-button icon-end="chevron-right"
                                                     icon-ratio="1.8"
                                                     type="primary"
                                                     size="large"
                                                     class="uk-text-large uk-margin-top"
                                                     @click="currentStep=2">CONTINUE</form-button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="reassurance">
                            <div class="uk-flex-uk-flex-start uk-flex uk-flex-middle uk-flex-center uk-margin-large-top uk-margin-auto ">
                                <div class="uk-margin-small-right" uk-icon="icon: lock; ratio:4"></div>
                                <h3 class="uk-margin-remove">Secure Payment<br> Pay securely - your information is protected by SSL encryption.</h3>
                            </div>
                        </div>
                        <div class="builder-extra-info uk-container-xlarge uk-margin-auto ">
                            <div class="product-extra-info uk-margin-large-top">
                                <div class="subscription-actions-content">
                                    <ul class="uk-subnav uk-subnav-pill uk-margin-remove-bottom" uk-tab>
                                        <li><a href="#">Technical Specification</a></li>
                                        <li><a href="#">Product Video</a></li>

                                    </ul>

                                    <div class=" product-extra-info-switcher uk-switcher uk-margin uk-margin-remove-top uk-overflow-hidden uk-padding-small">
                                        <div class="uk-overflow-auto">
                                            <table class="uk-table uk-table-striped">
                                                <caption>Technical Specification for thr iPhone 12 PRO</caption>
                                                <thead>
                                                <tr>
                                                    <th>Property</th>
                                                    <th>Value</th>
                                                    <th>Extra</th>
                                                </tr>
                                                </thead>


                                                <tbody>
                                                <tr>
                                                    <td>Memory</td>
                                                    <td>32GB / 64GB / 128GB</td>
                                                    <td>Also available @ 256GB</td>
                                                </tr>

                                                <tr>
                                                    <td>Color</td>
                                                    <td>Black, Mate, Red</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Screen Size</td>
                                                    <td>6"</td>
                                                    <td>Pro X model - 7"</td>
                                                </tr>
                                                <tr>
                                                    <td>Some State</td>
                                                    <td>value</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Some State</td>
                                                    <td>value</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Some State</td>
                                                    <td>value</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Some State</td>
                                                    <td>value</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Some State</td>
                                                    <td>value</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Some State</td>
                                                    <td>value</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <iframe style="width: 100%;height:70vh;" src="https://www.youtube.com/embed/cnXapYkboRQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- number -->
                <div class="builder-step-content uk-width-3-3 uk-animation-slide-left-small" v-show="currentStep == 2 ">
                    <div class="builder-step builder-step--select-number">
                        <div class="uk-card  uk-card-default"  v-if=" ! isNumberSelected">
                            <div class="uk-card-header">
                                <div class="uk-grid-small ">
                                    <div class="uk-width-expand">
                                        <h2 class=" uk-margin-remove-bottom">Select Your Phone Number</h2>
                                        <p class="uk-text-meta uk-margin-remove-top"><time datetime="2016-04-01T19:00">Either check for a desired number OR pick a suggested number below.</time></p>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-card-body">
                                <div class="select-number content"
                                     v-if="! isNumberSelected">

                                    <div class="number-check">
                                        <h4 class="uk-margin-remove">Check Number Availability</h4>
                                        <p class="uk-margin-remove uk-text-small">Once checked, if the number is available it is selected for you. You can change it before you order, if you regret.</p>
                                        <div class="uk-margin-top">
                                            <base-form
                                                    action="/test/random-boolean"
                                                    method="get"
                                                    formPath="form/ecommerce-checkNumberAvailability"
                                                    :expectedFields="1"
                                                    fieldWrapperClass="uk-flex uk-flex-bottom"
                                                    :formHandling="numberAvailabilityFormHandling"
                                                    error-display-position="absolute"
                                                    v-model="numberAvailabilityFormData"
                                                    @form:submitSuccess="handleAvailabilityCheckResult"
                                            ></base-form>

                                        </div>
                                        <div class="uk-card uk-card-body uk-padding-small uk-card-default uk-flex uk-flex-between uk-flex-middle"
                                             v-if="availabilityNumberAvailable === true">
                                            <div class="explain">
                                                <h3 class="uk-margin-remove"><icon-text icon="check" class="uk-text-success">Number is Available</icon-text></h3>
                                                <p class="uk-margin-remove">The number is selected for your subscription: {{numberAvailabilityFormData.prefix}}{{numberAvailabilityFormData.number}} </p>

                                            </div>
                                            <div class="cta">
                                                <form-button icon="cart" @click.prevent="finished = true">Add To Cart</form-button>
                                            </div>
                                        </div>
                                        <div v-if="availabilityNumberAvailable === false">
                                            <div class="uk-card uk-card-body uk-padding-small uk-card-default ">
                                                <div class="uk-flex uk-flex-between uk-flex-middle">
                                                    <div class="explain">
                                                        <h4 class="uk-margin-remove"><icon-text icon="close" class="uk-text-danger">Number Unavailable</icon-text></h4>
                                                        <div class="uk-margin-left">
                                                            <p class="uk-margin-remove">
                                                                This number is not available.<br>
                                                                You may try another number or select from the suggestions below.</p>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <text-separator>OR</text-separator>
                                    <div class="number-suggest">
                                        <h4 class="uk-margin-remove">Number suggestions</h4>
                                        <p class="uk-margin-remove uk-text-small">Click a number to select it. You will be able to change this before adding the products to the cart</p>
                                        <button-group class="uk-margin-top">
                                            <form-button
                                                    type="secondary"
                                                    v-for="number in suggestedNumbers"
                                                    @click="selectNumber(number)"
                                            >{{number}}</form-button>
                                        </button-group>
                                    </div>

                                </div>
                            </div>

                        </div>


                        <div class="number-select-finish uk-card uk-card-default"   v-if="isNumberSelected">
                            <div class="uk-card-header">
                                <div class=" ">
                                    <div class="uk-width-expand">
                                        <h2 class=" uk-margin-remove-bottom">Select Your Phone Number</h2>
                                        <p class="uk-text-meta uk-margin-remove-top">Your Number is Selected.</p>
                                    </div>
                                </div>
                            </div>
                            <div class=" uk-card-body  ">
                                <div class="uk-flex uk-flex-between uk-flex-middle">
                                    <div class="explain">
                                        <h3 class="uk-margin-remove"><icon-text icon="check" class="uk-text-success">Number Selected</icon-text></h3>
                                        <div class="uk-margin-left">
                                            <p class="uk-margin-remove">
                                                Your subscription is ready to be ordered.<br>
                                                The number is selected for your subscription: {{numberAvailabilityFormData.prefix}}{{numberAvailabilityFormData.number}} </p>
                                        </div>


                                    </div>
                                    <div class="cta">
                                        <form-button icon="cart"
                                                     size="large"
                                                     class="uk-text-large"
                                                     :iconRatio="2"
                                                     @click.prevent="finished = true">Add To Cart</form-button>
                                    </div>

                                </div>
                                <button-group class="uk-margin-top">
                                    <form-button icon="refresh"
                                                 type="secondary"
                                                 @click="clearSelectedNumber()">Select a Different Number</form-button>
                                </button-group>
                            </div>

                        </div>

                    </div>

                    <div class="reassurance">
                        <div class="uk-flex-uk-flex-start uk-flex uk-flex-middle uk-flex-center uk-margin-large-top uk-margin-auto ">
                            <div class="uk-margin-small-right" uk-icon="icon: lock; ratio:4"></div>
                            <h3 class="uk-margin-remove">Secured Data<br> Operate freely. Your data is secured.</h3>
                        </div>
                    </div>
                </div>



            </div>

            <!-- summary -->
            <div class="builder-summary  uk-margin-top uk-width-1-3 uk-margin-large-left ">
                <div class="summary-container"  ref="summaryStickyContainer">
                    <div class="uk-card uk-card-default uk-card-body uk-padding-remove builder-summary-card uk-overflow-auto">

                        <div class="uk-card-hat uk-background-secondary  uk-flex uk-flex-between uk-flex-middle">
                            <span class=" uk-text-inverse-color uk-margin-remove uk-text-inverse-color" >summary</span>
                            <div class="icon-toggle uk-flex uk-flex-center uk-flex-middle">
                                <span class="icon-toggle-icon"
                                      v-if=" !summaryMinimized"
                                      @click="summaryMinimized = true"
                                      uk-icon="icon: minus; ratio: 1.2"
                                      uk-tooltip="Click to minimize, you can expand it back any time!"></span>
                                <span class="icon-toggle-icon"
                                      v-if="summaryMinimized"
                                      @click="summaryMinimized = false"
                                      uk-icon="icon: plus; ratio: 1.2"
                                      uk-tooltip="Click to show full information"></span>
                            </div>
                        </div>
                        <!--
                        <div class="uk-card-hat summary-header uk-padding-small  uk-background-secondary uk-flex uk-flex-between uk-flex-middle">
                            <h2 class=" uk-text-inverse-color uk-margin-remove">summary</h2>
                            <div class="icon-toggle uk-flex uk-flex-center uk-flex-middle">
                                <span class="icon-toggle-icon"
                                  v-if=" !summaryMinimized"
                                  @click="summaryMinimized = true"
                                  uk-icon="icon: minus; ratio: 1.2"
                                  uk-tooltip="Click to minimize, you can expand it back any time!"></span>
                                <span class="icon-toggle-icon"
                                      v-if="summaryMinimized"
                                      @click="summaryMinimized = false"
                                      uk-icon="icon: plus; ratio: 1.2"
                                      uk-tooltip="Click to show full information"></span>
                            </div>
                        </div>-->
                        <div class="summary-main uk-padding uk-padding-remove-vertical uk-margin-top uk-animation-fade uk-animation-fast"
                             v-show="! summaryMinimized">
                            <div class="step-1-summary step-summary">
                                <div class=" uk-margin-bottom">
                                    <strong class="step-summary-title uk-text-primary uk-text-uppercase">Your Subscription</strong>
                                </div>
                                <div class="step-product-details uk-flex uk-margin-bottom">
                                    <div class="step-product-details-info uk-width-1-2 uk-margin-small-right">
                                        <h3 class="uk-margin-remove">{{currentPackage.limitText}} plan</h3>
                                        <span>{{currentPackage.title}}</span>
                                        <div class="subscription-number uk-margin-small-top">
                                            <span>{{addToCart.builder.package.options.commitment}} month commitment</span>
                                        </div>
                                        <div class="subscription-number uk-margin-small-top" v-if="isNumberSelected">
                                            <span>Phone Number:<br>{{selectedNumber}}</span>
                                        </div>
                                    </div>
                                    <div class="step-product-details-price uk-width-1-2 uk-text-right">
                                        <h3 class="uk-margin-remove-bottom"><strong>{{currentPackage.pricing[0].price}} F/MO</strong></h3>
                                        <span class="uk-text-small" >x{{currentPackage.pricing[0].period}} months then {{currentPackage.pricing[1].price}} F/MO</span>
                                    </div>
                                </div>
                                <div class="step-product-extra uk-flex ">
                                    <div class="uk-width-1-2">
                                        <a class="uk-text-secondary uk-text-underline" href="#" @click.prevent="">See more details</a>
                                    </div>
                                    <div class="uk-width-1-2 uk-text-right">
                                        <a class="uk-text-secondary uk-text-underline" href="#" @click.prevent="showPackageList()">Change</a>
                                    </div>
                                </div>
                            </div>

                            <div class="builder-summary-step-separator ">
                                <div class="icon uk-position-center uk-border-circle uk-padding-small uk-background-muted uk-text-secondary" uk-overlay-icon="icon: plus; ratio: 0.9"></div>
                                <hr>
                            </div>

                            <div class="step-2-summary">
                                <div class=" uk-margin-bottom">
                                    <strong class="step-summary-title uk-text-primary uk-text-uppercase">Your Device</strong>
                                </div>
                                <div class="step-product-details uk-flex uk-margin-bottom">
                                    <div class="step-product-details-info uk-width-1-2 uk-margin-small-right">
                                        <h3 class="uk-margin-remove">Apple iPhone 12 Pro</h3>
                                        <span>{{addToCart.options.memory}} Go {{translate('ecommerceCore.productOptionsDemo.fields.color.options.' + addToCart.options.color)}}</span>
                                    </div>
                                    <div class="step-product-details-price uk-width-1-2 uk-text-right">
                                        <h3 class="uk-margin-remove-bottom"><strong>

                                            {{demoPhonePrice}}
                                        </strong></h3>
                                        <span class="uk-text-small" >Tax included</span>
                                    </div>
                                </div>
                                <div class="step-product-extra uk-flex ">
                                    <div class="uk-width-1-2">
                                        <a class="uk-text-secondary uk-text-underline" href="#" @click.prevent="">See price details</a>
                                    </div>
                                </div>
                            </div>

                            <hr>



                        </div>

                        <div class="spacer uk-margin" v-show="summaryMinimized"></div>

                        <div class="summary-cta uk-padding uk-padding-remove-vertical uk-animation-fade uk-animation-fast">
                            <div class="finish-builder-add-to-cart uk-background-muted uk-padding-small uk-text-center">
                                <div class="availability uk-text-bold uk-margin-small-bottom">
                                    <span class="uk-text-success" uk-icon="check"></span>
                                    <span>Available Online</span>
                                </div>
                                <div class="cta-container">
                                    <form-button class="uk-font-bold"
                                                 size="medium"
                                                 icon="cart"
                                                 type="primary"
                                                 @click.prevent="finished = true">Add to Cart</form-button>
                                </div>
                            </div>
                            <div class="uk-text-success uk-text-center uk-margin-top uk-text-semibold uk-flex uk-flex-middle uk-flex-center">
                                <icon-text icon="lock">Secure Payment</icon-text>
                            </div>

                            <hr>
                        </div>

                        <div class="summary-footer uk-padding uk-padding-remove-vertical uk-margin-bottom uk-flex uk-flex-row-reverse" v-show="! summaryMinimized">
                            <a href="#" @click.prevent="$router.push({name: 'ecommerce-product', params:{productAlias: 'phone'}})">Get Phone Only</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div v-if=" finished"
                class="builder-finished  uk-container-xlarge uk-margin-large-top uk-margin-auto uk-padding uk-padding-remove-vertical uk-animation-slide-left-small">
            <added-to-cart></added-to-cart>
        </div>

    </div>


</template>

<script>
    import {nextTick} from 'vue';
    import ecommerceHero from "@/client/components/ecommerce/Hero.vue";
    import addedToCart from "@/client/components/ecommerce/AddedToCart.vue";


    import promotionBanner from "@/client/components/ecommerce/PromotionBanner.vue";
    import asyncOperations from "@/client/extensions/composition/asyncOperations";

    export default {
        props: {
            productAlias: {
                type: [String, Boolean],
                default: 'something-something'
            },
            demoMode: {
                default: null
            }
        },
        components: {
            ecommerceHero, promotionBanner, addedToCart
        },
        data: function () {
            return {
                addToCart: {
                    qty: 1,
                    options: {
                        color: 'red',
                        memory: '256'
                    },
                    builder: {
                        package: {
                            selected: 2,
                            options: {
                                commitment: 12,
                                discountType: 1
                            }
                        }
                    }
                },
                subscription: {
                    showGallery: false,
                    images: []
                },
                phone: {
                    showGallery: true,
                    images: [
                        {
                            src: 'images/products/iphone12_1.jpg',
                            thumbSrc: 'images/products/iphone12_1.jpg',
                        },
                        {
                            src: 'images/products/iphone12_2.jpg',
                            thumbSrc: 'images/products/iphone12_2.jpg',
                        },
                        {
                            src: 'images/products/iphone12_3.jpg',
                            thumbSrc: 'images/products/iphone12_3.jpg',
                        },
                    ],
                },
                packages: {
                  0 : {
                      title: 'A Package with 100MO & somethings',
                      subTitle: '<strong>Something to highlight</strong> with this package and 24 month commitment',
                      dataMethod: 'FLAT RATE 4G',
                      limitText: '8Go',
                      commitmentTime: 12,
                      includes: [
                          'Unlimited calls, SMS / MMS ',
                          '8GO in French Polynesia'
                      ],
                      benefits: [
                          {
                              'link': '#',
                              'label': 'Some other benefits of package'
                          }
                      ],
                      pricing: {
                          0 : {
                              period: 12,
                              price: 150
                          },
                          1: {
                              period: 24,
                              price: 199,
                          }
                      }
                  },
                  1 : {
                      title: 'Another package title',
                      subTitle: '<strong>Something else to highlight</strong> some other text',
                      dataMethod: 'FLAT RATE 4G & 5G',
                      limitText: '22Go',
                      commitmentTime: 12,
                      includes: [
                          'Unlimited calls, SMS / MMS ',
                          '22GO in French Polynesia'
                      ],
                      benefits: [
                          {
                              'link': '#',
                              'label': 'Some benefit of package'
                          }
                      ],
                      pricing: {
                          0 : {
                              period: 12,
                              price: 900
                          },
                          1: {
                              period: 24,
                              price: '1 200',
                          }
                      }
                  },
                  2 : {
                      title: 'Some Package with 10MO & Smartphone Benefits',
                      subTitle: '<strong>Something to highlight</strong> with this package and 24 month commitment',
                      dataMethod: 'FLAT RATE 4G',
                      limitText: '10Go',
                      commitmentTime: 12,
                      includes: [
                          'Unlimited calls, SMS / MMS ',
                          '10GO in French Polynesia'
                      ],
                      benefits: [
                          {
                              'link': '#',
                              'label': 'Some benefit of package'
                          }
                      ],
                      pricing: {
                          0 : {
                              period: 12,
                              price: 99
                          },
                          1: {
                              period: 24,
                              price: 350,
                          }
                      }
                  },
                  3 : {
                      title: 'A 4th Package & Smartphone Benefits',
                      subTitle: '<strong>Highlight</strong> Text for the highlight',
                      dataMethod: 'FLAT RATE 4G',
                      limitText: '1Go',
                      commitmentTime: 12,
                      includes: [
                          'Unlimited calls, SMS / MMS ',
                          '1GO in French Polynesia'
                      ],
                      benefits: [
                          {
                              'link': '#',
                              'label': 'Some Special Benefit'
                          }
                      ],
                      pricing: {
                          0 : {
                              period: 12,
                              price: 375
                          },
                          1: {
                              period: 24,
                              price: 499,
                          }
                      }
                  },
                },
                steps: {
                    0: {
                        complete: true,
                    },
                    1: {
                        complete: true,
                    },
                    2: {
                        complete: false,
                    },
                },
                currentStep: 1,
                summaryMinimized: false,
                numberAvailabilityFormData: {
                    prefix: '089',
                    number: '1231231'
                },
                numberAvailabilityFormHandling: {},
                availabilityNumberAvailable: null,
                selectedNumber: '',
                isNumberSelected: false,
                suggestedNumbers: ['089 123 298', '089 123 339' , '089 442 864' ,'089 332 325'],
                finished: false,
                loginPromptMode: 0,
            };
        },
        computed: {
            product() {
                if (this.productAlias === 'subscription') {
                    return this.subscription;
                } else {
                    return this.phone;
                }
            },
            currentPackage() {
                if ( ! this.addToCart.builder.package.selected) {
                    return false;
                }

                return this.packages[this.addToCart.builder.package.selected];
            },
            demoPhonePrice () {
                if (this.addToCart.options.memory == '256') {
                    return '1 500 F';
                }

                if (this.addToCart.options.memory == '512') {
                    return '2 125 F';
                }

                if (this.addToCart.options.memory == '1024') {
                    return '2 555 F';
                }

                return '1 950 F'
            },
            demoDiscounts() {

                if (this.addToCart.builder.package.options.commitment == 12) {
                    return [
                        {value: '0', label: 'Discount: 700 F'},
                        {value: '1', label: 'Discount: 70 F/MO'},

                    ];
                } else {
                    return [
                        {value: '0', label: 'Discount: 1 000 F'},
                        {value: '1', label: 'Discount: 100 F/MO'},

                    ];
                }

            }

        },
        methods: {
            sourceToBackgroundCss (src) {
                return "background-image: url('"+src+"');"
            },
            // TODO: extract method. this is also in checkout
            scrollToRef(refKey) {
                if (typeof window != 'object') {
                    return;
                }

                let el = this.$refs[refKey] || false;
                let isElementXPercentInViewport = (el, percentVisible) => {
                    let
                        rect = el.getBoundingClientRect(),
                        windowHeight = (window.innerHeight || document.documentElement.clientHeight);

                    return !(
                        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
                        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
                    )
                };

                if (! el) {
                   return;
                }
                if ( isElementXPercentInViewport(el, 20)) {
                    return;
                }

                if (el) {
                    let offsetPosition =  el.getBoundingClientRect().top - 210;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });

                }
            },
            refreshStickySummary () {
                let el = this.$refs['summaryStickyContainer'];
                this.$s.ui.adapter.sticky(el, {offset:150, bottom: true});
            },
            showPackageList() {
                this.currentStep = 0;
                nextTick(()=>{
                    this.scrollToRef('packageList')
                });
            },
            clearSelectedNumber() {
                this.selectedNumber = null;
                this.isNumberSelected = false;
                this.numberAvailabilityFormData.prefix = '089';
                this.numberAvailabilityFormData.number = '';
                this.availabilityNumberAvailable = null;
            },
            selectNumber(number)
            {
                this.selectedNumber = number.replace(' ', '').replace(' ', '');
                this.isNumberSelected = true;
            },
            handleAvailabilityCheckResult (eventData) {
                let status = eventData.result.data;

                this.$s.ui.notification('Demo result from server: ' + +status);
                if (status) {
                    this.selectedNumber = this.numberAvailabilityFormData.prefix + ' ' + this.numberAvailabilityFormData.number;
                    this.isNumberSelected = true;
                } else {
                    this.availabilityNumberAvailable = false;
                }
            },
            closeLoginPrompt() {
                this.$s.ui.modal(this.$refs.loginModal).hide();
            },
            demoLogin () {
                this.$s.ui.notification('Welcome back Aldwin, please take advantage of this custom offer', 'success');
                this.closeLoginPrompt();
            }
        },
        mounted () {
            this.refreshStickySummary();

            if (this.demoMode == 'login-popup') {
                this.$s.ui.modal(this.$refs.loginModal).show();
            }

        },
        watch : {
            currentStep() {
                if (this.$.isMounted) {

                    this.refreshStickySummary();
                }

            },
            isNumberSelected(newVal) {
                this.steps['2'].complete = newVal;
            }
        }
    }
</script>

<style scoped lang="scss">



    .summary-steps {

     }

    .product-slideshow-nav {
        li {

            a {
                height: 50px;
                width: 70px;
                display: inline-block;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    .old-price {
        text-decoration: line-through;
    }

    .product-extra-info-switcher {
        border: 1px solid var(--global-border);
        border-top: none;
    }

    .builder-summary-step-separator {
        position: relative;
        height: calc(var(--global-margin) * 2);
        margin: calc(var(--global-margin) * 1);
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        .icon, hr {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        hr {
            position: relative;
            z-index: 1;
            margin: 0;
        }

        .icon {
            z-index: 2;
            border: calc(var(--global-margin) * 0.8) solid var(--global-inverse-color);
        }
    }


    .builder-progress{
        position: relative;
        z-index: 3;
    }

    .builder-main {
        position: relative;
        z-index: 2;
    }

    .builder-summary {
        position: relative;
        z-index: 2;
    }

    .builder-summary-card {
        position: relative;
        z-index: 10;
        max-height: calc(100vh - 170px );
    }

    .summary-header {
        padding-top: calc(var(--global-margin) * 0.65);
        padding-bottom: calc(var(--global-margin) * 0.65);
    }

    .step-summary-title {
        font-size: calc(var(--global-font-size));
        font-weight: bold;
        letter-spacing: -0.8px;
    }

    .icon-toggle {
        background: var(--global-inverse-color);
        padding: calc(var(--global-margin) * 0.1);
        border-radius: 50%;
        transition: background-color 150ms, color 150ms, transform 350ms ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: var(--global-primary-background);
            color: var(--global-inverse-color);
            transform: rotate(180deg);
        }

        .icon-toggle-icon {
            position: relative;
            top: 1px;
        }
    }

    .advantages-list li a {
        text-decoration: underline;
    }

    .packages-slider-inner{
        padding: 0 10px;
    }

    .package-box {
        .package-summary {
            .package-limit{
                font-size: calc(var(--global-font-size) * 2.4);

            }
            .content {
                margin-bottom: calc(var(--global-margin) * 2);
            }
        }
        .package-info-separator {
            margin-top: calc(var(--global-margin) * 1.5);
            margin-bottom: calc(var(--global-margin) * 1.5);

        }
    }

    .select-number-title {
        margin-bottom: calc(var(--global-margin) * 1.7);
    }

    .package-options-explain {
        margin-bottom: calc(var(--global-margin) * 1.5);
    }
    .discount-type-wrapper {
        position: relative;

        .overlay {
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            cursor: not-allowed;
            display: block;
            background: rgba(255,255,255,0.6);
            z-index: 2;
        }
    }
</style>
