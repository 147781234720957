<template>
    <div class="promotion-banner  uk-background-muted" >
        <div class="uk-background-muted uk-padding-small">
            <div class="uk-container uk-text-center uk-flex uk-flex-center">
                <h3 class="uk-text-center uk-flex uk-flex-middle uk-text-center">
                    <span class="uk-text-primary" uk-icon="icon:check;ratio:1.5;"></span>Promotion banner text, You can get something for something. <a class="uk-margin-small-left">Some link </a></h3>
            </div>
        </div>
    </div>
</template>

<script>

    //    import asyncOperations from "@/client/extensions/composition/asyncOperations";
    export default {
        props: {
            title: {
                type: [String, Boolean],
                default: false
            },
            background: {
                type: [String],
                default: 'ecommerce/headers/sample_header_2.jpg'
            }
        },
        components: {

        },
        data: function () {
            return {

            };
        },
        computed: {
            heroBackgroundStyle () {
                let url = utilities.requireAsset(this.background);
                return {
                    backgroundImage : "url('"+url+"')",

                };
            },

        },
        methods: {

        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">

    .hero {
        position: relative;
        height: 150px;

        .background {
            height: 100%;
            width: 100%;
            left: 50%;
            top: 50%;

            z-index: 0;
            overflow: hidden;


            .background-inner {

                height: calc(100% + 40px);
                width: calc(100% + 40px);
                left: calc(50% - 20px);
                top: calc(50% - 20px);

                background-size: cover;
                filter: blur(2px);
                -webkit-filter: blur(2px);
            }
        }

        .background-overlay {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(0,0,0,0.35);
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
            height: 100%;
        }
    }



</style>
